interface ConfigInterface {
  element: HTMLElement;
  getData(key);
}

declare const IS_DEV: boolean;

export class Config implements ConfigInterface {

  public element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;
    if (IS_DEV) {
      this.debug();
    }
  }

  /**
   * Возвращает значение конфига
   * @param key
   * @param defaultValue
   * @param {null|function} filter
   * @returns {any}
   */
  public getData(key, defaultValue: any = null, filter: any = null) {
    try {
      let attributeValue = this.getAttribute(key);
      if (attributeValue === 'true') {
        return true;
      } else if (attributeValue === 'false') {
        return false;
      }
      if (typeof filter === 'function') {
        attributeValue = filter(attributeValue);
      }
      return (attributeValue !== null && attributeValue !== undefined ) ? attributeValue : defaultValue;
    } catch (e) {
      console.warn(e.message || e);
    }
  }

  /**
   * Возвращает значение атрибута тега
   * @param key
   * @returns {string}
   */
  public getAttribute(key) {
    return this.element.getAttribute(`data-config-${key}`);
  }

  /**
   * Отладка значений конфига компонента
   */
  public debug(): void {
    const attributes: any = this.element.attributes;
    console.log(this.element);
    for (const attribute of attributes) {
      if (attribute.name.match(/(data-config-*)/)) {
        console.log('%c' + attribute.name + ' = %c' + attribute.value,
          'color: green;',
          'color: red;',
        );
      }
    }
    console.log('🏁');
  }

}
