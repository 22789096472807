import * as noUiSlider from 'nouislider';
import * as wNumb from 'wnumb';

import './styles/style.scss';

import {AbstractElement} from '../base/src/abstract-element';
import {Dom} from '../base/src/utils/dom';

export class Slider extends AbstractElement {

  public init() {
    const format = wNumb({ decimals: 0 });
    const suffixValue = this.config.getData('suffix', '');
    const prefixValue = this.config.getData('prefix', '');
    const wNumbConfig = { decimals: 0, thousand: ' ', prefix: prefixValue, suffix: suffixValue };
    const defaultValue = this.config.getData('default-value', 100, format.from);
    const minValue = this.config.getData('min', defaultValue, (min) => (min === (0 || '0') ? 0 : format.from(min)));
    const maxValue = this.config.getData('max', 100000, format.from);
    const minCurrent = this.config.getData('min-current', minValue, format.from);
    const maxCurrent = this.config.getData('max-current', maxValue, format.from);
    const tooltips = this.config.getData('tooltips', false);
    const showInputs = this.config.getData('inputs', false);
    const inputPopover = this.config.getData('popover', false);
    const filterName = this.config.getData('filter-name', false);
    const slider = noUiSlider.create(this.element, {
      start: [minCurrent, maxCurrent],
      step: this.config.getData('step', 1, format.from),
      tooltips: tooltips ? [wNumb(wNumbConfig), wNumb(wNumbConfig)] : false,
      connect: true,
      range: {
        min: minValue,
        max: maxValue
      },
    });

    if (!showInputs) {

      slider.on('set', (e) => {
        this.event.trigger('set', e);
      });
      slider.on('update', (e) => {
        this.event.trigger('update', e);
      });

    } else {

      const formatDec = wNumb(wNumbConfig);
      const sliderNode: HTMLElement = this.element.parentElement;

      const forPopover = (inputPopover ? 'slider-popover' : '');

      const sliderInputWrap = Dom.createElement('div', `
        <input type="text" value="${minCurrent}" class="ariflex-slider__input lower-input ${forPopover}"
            data-attr-name="${filterName}" aria-label="Минимальная цена">
        <span class="ariflex-slider__input-dash">&mdash;</span>
        <input type="text" value="${maxCurrent}" class="ariflex-slider__input upper-input ${forPopover}"
            data-attr-name="${filterName}" aria-label="Максимальная цена">
      `, 'ariflex-slider__input-wrap');
      Dom.prepend(sliderInputWrap, sliderNode);

      const hideSpan = (selector) => {
        sliderNode.querySelector(selector).setAttribute('style', 'display: none;');
      };
      hideSpan('.lower');
      hideSpan('.upper');

      const deleteFormat = (el) => {
        return format.from(el.value);
      };

      const inputMin: HTMLInputElement = sliderNode.querySelector('.lower-input');
      const inputMax: HTMLInputElement = sliderNode.querySelector('.upper-input');

      inputMin.addEventListener('focus', () => {
        inputMin.value = deleteFormat(inputMin);
      });
      inputMax.addEventListener('focus', () => {
        inputMax.value = deleteFormat(inputMax);
      });
      inputMin.addEventListener('focusout', () => {
        slider.set([deleteFormat(inputMin), null]);
      });
      inputMax.addEventListener('focusout', () => {
        slider.set([null, deleteFormat(inputMax)]);
      });
      inputMin.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          slider.set([deleteFormat(inputMin), null]);
        }
      });
      inputMax.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          slider.set([null, deleteFormat(inputMax)]);
        }
      });
      if (inputPopover) {
        inputMin.addEventListener('mouseout', () => {
          inputMin.dispatchEvent(new CustomEvent('focusout'));
        });
        inputMax.addEventListener('mouseout', () => {
          inputMax.dispatchEvent(new CustomEvent('focusout'));
        });
      }

      const updateInputValue = () => {
        const minFromSpan: HTMLElement = sliderNode.querySelector('.lower .value');
        const maxFromSpan: HTMLElement = sliderNode.querySelector('.upper .value');
        inputMin.value = formatDec.to(parseInt(minFromSpan.innerText, 10));
        inputMax.value = formatDec.to(parseInt(maxFromSpan.innerText, 10));
      };

      slider.on('set', (e) => {
        this.event.trigger('set', e);
        updateInputValue();
      });

      slider.on('update', (e) => {
        this.event.trigger('update', e);
        updateInputValue();
      });

    }
  }

}

Dom.ready('DOMContentLoaded pjax:done', document, (e) => {
  Dom.getElementsByClass('ariflex-slider', (element) => {
    const slider = new Slider(element);
  });
});
