interface EventInterface {
  element: HTMLElement;
  trigger(event, data);
}

declare const IS_DEV: boolean;

export class Event implements EventInterface {

  public element: HTMLElement;

  constructor(element: HTMLElement) {
    this.element = element;
  }

  /**
   * Триггер события
   * @returns {any}
   * @param event
   * @param data
   * @param toAttribute
   */
  public trigger(event: string, data, toAttribute: boolean = false) {
    try {
      if (!toAttribute) {
        const e = new CustomEvent(event, {detail: data});
        this.element.dispatchEvent(e);
      } else {
        const e = this.element.getAttribute(event);
        new Function('e', e)(data);
      }
    } catch (e) {
      console.warn(e.message || e);
    }
  }

}
