import {Config} from './utils/config';
import {Event} from './utils/event';

interface AbstractElementInterface {
  element: HTMLElement;
  init();
}

export class AbstractElement implements AbstractElementInterface {

  public element: HTMLElement;
  private configObject: Config;
  private eventObject: Event;

  /**
   * Constructor
   * @param {HTMLElement} element
   */
  constructor(element: HTMLElement) {
    this.element = element;
    this.init();
  }

  /**
   * Возвращает объект Config
   * @returns {Config}
   */
  get config() {
    if (typeof this.configObject === 'undefined') {
      return this.configObject = new Config(this.element);
    }
    return this.configObject;
  }

  /**
   * Возвращает объект Event
   * @returns {Event}
   */
  get event() {
    if (typeof this.eventObject === 'undefined') {
      return this.eventObject = new Event(this.element);
    }
    return this.eventObject;
  }

  /**
   * Initialize method for extended class
   */
  public init() {
    // init
  }

}
